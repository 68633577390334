import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import './index.css'
import App from './App'

import authReducer from './store/reducers/auth'
import usersReducer from './store/reducers/users'
//import companiesReducer from './store/reducers/companies'
//import brandsReducer from './store/reducers/brands'
import agentsReducer from './store/reducers/agent'
import questionnairesReducer from './store/reducers/questionnaires'
import questionnairesCategoriesReducer from './store/reducers/questionnairesCategories'
import alertsReducer from './store/reducers/alerts'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    //companies: companiesReducer,
    //brands: brandsReducer,
    questionnaires: questionnairesReducer,
    questionnairesCategories: questionnairesCategoriesReducer,
    users: usersReducer,
    agents: agentsReducer,
    alerts: alertsReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
