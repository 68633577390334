import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utils'

const initialState = {
    agent: null,
    companies: [],
    brands: [],
    tracking: null,
    brand: null,
    error: null,
    title: 'Communication channel',
    text: 'Communication channel',
    questions: [],
    outcomes: {},
    loading: false,
    step: 1,
    accepted: false,
    success: false,
    successMessage: null,
    track_gender: true,
    track_language: false,
    languages: [],
};

const fetchBrands = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        brands: action.brands,
        companies: action.companies,
    });
}

const fetchInfo = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        agent: action.agent,
    });
}

const ajaxFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
}

const loading = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: false,
        successMessage: null
    });
}

const infoUpdateSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: true,
        successMessage: "Information Updated.",
        agent: action.agent,
    });
}

const submitSuccess = (state, action) => {
    return updateObject(state, {
        brands: [],
        tracking: null,
        brand: null,
        error: null,
        title: 'Communication channel',
        text: 'Communication channel',
        questions: [],
        outcomes: {},
        loading: false,
        step: 1,
        accepted: true,
    });
}

const reset = (state, action) => {
    return updateObject(state, {
        brands: [],
        tracking: null,
        brand: null,
        error: null,
        title: 'Communication channel',
        text: 'Communication channel',
        questions: [],
        outcomes: {},
        loading: false,
        step: 1,
        accepted: false,
    });
}

const initQuestionarySuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        brand: action.brand,
        title: action.title,
        text: action.text,
        questions: action.questions,
        tracking: action.tracking_id,
        step: 1,
        track_gender: action.track_gender,
        track_language: action.track_language,
        languages: action.languages,
        outcomes: {
            1 : {
                id: null,
                title: action.title,
                text: action.text,
                questions: action.questions
            }
        }
    });
}

const nextStep = (state, action) => {
    const oldState = {...state};
    const updatedStep = oldState.step + 1;
    const oldOutcomes = {...oldState.outcomes};
    const updatedOutcomes = oldOutcomes;
    updatedOutcomes[updatedStep] = {
        id: action.outcome_id,
        title: action.title,
        text: action.text,
        questions: action.questions
    };

    return updateObject(state, {
        loading: false,
        error: null,
        title: action.title,
        text: action.text,
        questions: action.questions,
        step: updatedStep,
        outcomes: updatedOutcomes
    });
}

const previousStep = (state, action) => {
    const oldState = {...state};
    const updatedStep = oldState.step - 1;
    const oldOutcomes = {...oldState.outcomes};
    oldOutcomes[oldState.step] = undefined;
    const updatedOutcomes = oldOutcomes;

    return updateObject(state, {
        loading: false,
        error: null,
        title: oldOutcomes[updatedStep].title,
        text: oldOutcomes[updatedStep].text,
        questions: oldOutcomes[updatedStep].questions,
        step: updatedStep,
        outcomes: updatedOutcomes
    });
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AGENT_FETCH_INFO_SUCCESS: return fetchInfo(state, action);
        case actionTypes.AGENT_BRANDS_FETCH_LIST: return fetchBrands(state, action);
        case actionTypes.AGENT_AJAX_FAIL: return ajaxFail(state, action);
        case actionTypes.AGENT_LOADING: return loading(state, action);
        case actionTypes.AGENT_BRAND_INIT: return initQuestionarySuccess(state, action);
        case actionTypes.AGENT_NEXT_STEP: return nextStep(state, action);
        case actionTypes.AGENT_PREVIOUS_STEP: return previousStep(state, action);
        case actionTypes.AGENT_SUBMIT_SUCCESS: return submitSuccess(state, action);
        case actionTypes.AGENT_RESET: return reset(state, action);
        case actionTypes.AGENT_INFO_UPDATE: return infoUpdateSuccess(state, action);

        default: return state;
    }
}

export default reducer;
