export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_AJAX_START = 'AUTH_AJAX_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PASSWORD_UPDATED = 'AUTH_PASSWORD_UPDATED';
export const AUTH_PASSWORD_RECOVERY_SENT = 'AUTH_PASSWORD_RECOVERY_SENT';
export const AUTH_AJAX_FAIL = 'AUTH_AJAX_FAIL';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const GLOBAL_ALERT_OPEN = 'GLOBAL_ALERT_OPEN';
export const GLOBAL_ALERT_CLOSE = 'GLOBAL_ALERT_CLOSE';

export const ADMIN_USERS_AJAX_LOADING = 'ADMIN_USERS_AJAX_LOADING';
export const ADMIN_USERS_AJAX_FAIL = 'ADMIN_USERS_AJAX_FAIL';
export const ADMIN_USERS_FETCH_LIST_SUCCESS = 'ADMIN_USERS_FETCH_LIST_SUCCESS';
export const ADMIN_USERS_FETCH_USER = 'ADMIN_USERS_FETCH_USER';
export const ADMIN_USERS_FETCH_USER_SUCCESS = 'ADMIN_USERS_FETCH_SUCCESS';
export const ADMIN_USERS_USER_UPDATE_SUCCESS = 'ADMIN_USERS_USER_UPDATE_SUCCESS';
export const ADMIN_USERS_USER_CREATE = 'ADMIN_USERS_USER_CREATE';
export const ADMIN_USERS_USER_CREATE_SUCCESS = 'ADMIN_USERS_USER_CREATE_SUCCESS';
export const ADMIN_USERS_USER_DELETE_SUCCESS = 'ADMIN_USERS_USER_DELETE_SUCCESS';
export const ADMIN_USERS_USER_PASSOWORD_UPDATE_SUCCESS = 'ADMIN_USERS_USER_PASSOWORD_UPDATE_SUCCESS';
export const ADMIN_USERS_USER_AVATAR_UPDATE_SUCCESS = 'ADMIN_USERS_USER_AVATAR_UPDATE_SUCCESS';

export const ADMIN_COMPANIES_AJAX_LOADING = 'ADMIN_COMPANIES_AJAX_LOADING';
export const ADMIN_COMPANIES_AJAX_FAIL = 'ADMIN_COMPANIES_AJAX_FAIL';
export const ADMIN_COMPANIES_FETCH_LIST_SUCCESS = 'ADMIN_COMPANIES_FETCH_LIST_SUCCESS';
export const ADMIN_COMPANIES_FETCH_COMPANY_SUCCESS = 'ADMIN_COMPANIES_FETCH_COMPANY_SUCCESS';
export const ADMIN_COMPANIES_COMPANY_CREATE_SUCCESS = 'ADMIN_COMPANIES_COMPANY_CREATE_SUCCESS';
export const ADMIN_COMPANIES_COMPANY_UPDATE_SUCCESS = 'ADMIN_COMPANIES_COMPANY_UPDATE_SUCCESS';
export const ADMIN_COMPANIES_COMPANY_DELETE_SUCCESS = 'ADMIN_COMPANIES_COMPANY_DELETE_SUCCESS';
export const ADMIN_COMPANIES_COMPANY_ADD_AGENT_SUCCESS = 'ADMIN_COMPANIES_COMPANY_ADD_AGENT_SUCCESS';

export const ADMIN_BRANDS_AJAX_LOADING = 'ADMIN_BRANDS_AJAX_LOADING';
export const ADMIN_BRANDS_AJAX_FAIL = 'ADMIN_BRANDS_AJAX_FAIL';
export const ADMIN_BRANDS_FETCH_LIST_SUCCESS = 'ADMIN_BRANDS_FETCH_LIST_SUCCESS';
export const ADMIN_BRANDS_FETCH_BRAND_SUCCESS = 'ADMIN_BRANDS_FETCH_BRAND_SUCCESS';
export const ADMIN_BRANDS_BRAND_UPDATE_SUCCESS = 'ADMIN_BRANDS_BRAND_UPDATE_SUCCESS';
export const ADMIN_BRANDS_BRAND_CREATE_SUCCESS = 'ADMIN_BRANDS_BRAND_CREATE_SUCCESS';
export const ADMIN_BRANDS_BRAND_DELETE_SUCCESS = 'ADMIN_BRANDS_BRAND_DELETE_SUCCESS';

export const ADMIN_QUESTIONNAIRES_FETCH_LIST = 'ADMIN_QUESTIONNAIRES_FETCH_LIST';
export const ADMIN_QUESTIONNAIRES_FETCH_SUCCESS = 'ADMIN_QUESTIONNAIRES_FETCH_SUCCESS';
export const ADMIN_QUESTIONNAIRES_FETCH_FAIL = 'ADMIN_QUESTIONNAIRES_FETCH_FAIL';
export const ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE = 'ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE';
export const ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE_SUCCESS = 'ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE_SUCCESS';
export const ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE_FAIL = 'ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE_FAIL';
export const ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_UPDATE_SUCCESS = 'ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_UPDATE_SUCCESS';
export const ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE = 'ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE';
export const ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_SUCCESS = 'ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_SUCCESS';
export const ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_FAIL = 'ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_FAIL';
export const ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_DELETE_SUCCESS = 'ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_DELETE_SUCCESS';

export const AGENT_FETCH_INFO_SUCCESS = 'AGENT_FETCH_INFO_SUCCESS';
export const AGENT_BRANDS_FETCH_LIST = 'AGENT_BRANDS_FETCH_LIST';
export const AGENT_AJAX_FAIL = 'AGENT_AJAX_FAIL';
export const AGENT_LOADING = 'AGENT_LOADING';
export const AGENT_BRAND_INIT = 'AGENT_BRAND_INIT';
export const AGENT_NEXT_STEP = 'AGENT_NEXT_STEP';
export const AGENT_PREVIOUS_STEP = 'AGENT_PREVIOUS_STEP';
export const AGENT_SUBMIT_SUCCESS = 'AGENT_SUBMIT_SUCCESS';
export const AGENT_RESET = 'AGENT_RESET';
export const AGENT_INFO_UPDATE = 'AGENT_INFO_UPDATE';

export const ADMIN_QUESTION_CAT_FETCH_LOADING = 'ADMIN_QUESTION_CAT_FETCH_LOADING';
export const ADMIN_QUESTION_CAT_FETCH_LIST = 'ADMIN_QUESTION_CAT_FETCH_LIST';
export const ADMIN_QUESTION_CAT_FETCH_FAIL = 'ADMIN_QUESTION_CAT_FETCH_FAIL';
export const ADMIN_QUESTION_CAT_FETCH_QUESTIONNAIRE = 'ADMIN_QUESTION_CAT_FETCH_QUESTIONNAIRE';
export const ADMIN_QUESTION_CAT_FETCH_QUESTIONNAIRE_SUCCESS = 'ADMIN_QUESTION_CAT_FETCH_QUESTIONNAIRE_SUCCESS';
export const ADMIN_QUESTION_CAT_QUESTIONNAIRE_UPDATE_SUCCESS = 'ADMIN_QUESTION_CAT_QUESTIONNAIRE_UPDATE_SUCCESS';
export const ADMIN_QUESTION_CAT_QUESTIONNAIRE_CREATE = 'ADMIN_QUESTION_CAT_QUESTIONNAIRE_CREATE';
export const ADMIN_QUESTION_CAT_QUESTIONNAIRE_DELETE = 'ADMIN_QUESTION_CAT_QUESTIONNAIRE_DELETE';
