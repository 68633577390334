import * as actionTypes from './actionTypes'
import axios from '../../axios-logged'

export const ajaxLoading = () => {
    return {
        type: actionTypes.ADMIN_COMPANIES_AJAX_LOADING
    }
}

export const ajaxFail = (error) => {
    return {
        type: actionTypes.ADMIN_USERS_AJAX_FAIL,
        error: error
    }
}

export const usersLoadSuccess = (users) => {
    return {
        type: actionTypes.ADMIN_USERS_FETCH_LIST_SUCCESS,
        users: users
    }
}

export const userLoadSuccess = (user) => {
    return {
        type: actionTypes.ADMIN_USERS_FETCH_USER_SUCCESS,
        user: user
    }
}

export const userUpdateSuccess = (user) => {
    return {
        type: actionTypes.ADMIN_USERS_USER_UPDATE_SUCCESS,
        user: user
    }
}

export const userCreateSuccess = (user) => {
    return {
        type: actionTypes.ADMIN_USERS_USER_CREATE_SUCCESS,
        user: user
    }
}

export const passwordUpdateSuccess = () => {
    return {
        type: actionTypes.ADMIN_USERS_USER_PASSOWORD_UPDATE_SUCCESS,
    }
}

export const avatarUpdateSuccess = (avatar) => {
    return {
        type: actionTypes.ADMIN_USERS_USER_AVATAR_UPDATE_SUCCESS,
        avatar: avatar
    }
}


export const deleteSuccess = (user) => {
    return {
        type: actionTypes.ADMIN_USERS_USER_DELETE_SUCCESS,
    }
}

export const updateUser = (token, id, data) => {

    return dispatch => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.put('admin/users/' + id, data)
        .then(response => {
            //console.log(response);
            dispatch(userUpdateSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const createUser = (token, data) => {

    return dispatch => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('admin/users', data)
        .then(response => {
            dispatch(userCreateSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const userPasswordUpdate = (token, id, password) => {

    return dispatch => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.put('admin/users/' + id + '/password', password)
        .then(response => {
            dispatch(passwordUpdateSuccess());
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const userAvatarChange = (token, id, avatar) => {

    return dispatch => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.put('admin/users/' + id + '/avatar', avatar)
        .then(response => {
            dispatch(avatarUpdateSuccess(response.avatar));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}
