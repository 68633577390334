import React from 'react';
import Loadable from 'react-loadable'
import AgentLayout from './containers/RolesLayout/AgentLayout'

function Loading() {
  return <div>Loading...</div>;
}

const Outcomes = Loadable({
  loader: () => import('./views/Role/Agent/Outcomes/Outcomes'),
  loading: Loading,
});

const Questionnaire = Loadable({
  loader: () => import('./views/Role/Agent/Outcomes/Questionnaire/Questionnaire'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('./views/Role/Agent/Settings/Settings'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: AgentLayout },
  { path: '/outcomes', exact: true, name: 'Outcomes', component: Outcomes },
  { path: '/outcomes/:id', exact: true, name: 'Questionnaire', component: Questionnaire },
  { path: '/settings', name: 'Settings', component: Settings },
];

export default routes;
