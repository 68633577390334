import React, { Component } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css'
// Import Main styles for this application
import './scss/style.css'

import * as actions from './store/actions'

// Containers
import { DefaultLayout } from './containers'
import AgentLayout from './containers/RolesLayout/AgentLayout'
import ClientLayout from './containers/RolesLayout/ClientLayout'
// Pages
import { Login, Page404, Page500 } from './views/Pages'

class App extends Component {
    componentDidMount () {
        this.props.onTryAutoSignup()
    }

    render() {
        
        ReactGA.initialize('UA-129408554-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        if ( this.props.isAuth ) {
            let appLayout = null;

            if(this.props.role === 'admin'){
                appLayout = <Route path="/" name="Home" component={DefaultLayout} />;
            } else if(this.props.role === 'agent'){
                appLayout = <Route path="/" name="Home" component={AgentLayout} />;
            } else if(this.props.role === 'client'){
                appLayout = <Route path="/" name="Home" component={ClientLayout} />;
            }
            return (
                <Switch>
                    {appLayout}
                    <Route exact path="/404" name="Page 404" component={Page404} />
                    <Route exact path="/500" name="Page 500" component={Page500} />
                </Switch>
            );
        }

        return (
            <Switch>
                <Route exact path="/login" name="Login Page" component={Login} />
                <Route exact path="/404" name="Page 404" component={Page404} />
                <Route exact path="/500" name="Page 500" component={Page500} />
                <Redirect to="/login" />
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        role: state.auth.role,
        allow_export: state.auth.allow_export
    }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch( actions.authCheckState() )
  }
}

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) )
