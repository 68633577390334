import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utils'

const initialState = {
    list: [],
    questionnaire: null,
    error: null,
    loading: false,
    success: false,
    saveAndExit: false
};

const fetchQuestionnaires = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: false,
        saveAndExit: false
    });
}

const fetchQuestionnaire = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: false
    });
}

const createQuestionnaire = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: false
    });
}

const questionnairesLoadSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: false,
        list: action.questionnaires,
    });
}

const questionnaireLoadSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: false,
        questionnaire: action.questionnaire,
    });
}

const questionnaireUpdateSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: true,
        questionnaire: action.questionnaire,
        saveAndExit: action.saveAndExit
    });
}

const questionnaireCreateSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: true,
        questionnaire: action.questionnaire,
    });
}

const questionnaireDeleteSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: true,
        questionnaire: null,
    });
}


const questionnairesLoadFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: false,
        error: action.error
    });
}

const createQuestionnaireFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: false,
        error: action.error
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_QUESTIONNAIRES_FETCH_LIST: return fetchQuestionnaires(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_FETCH_SUCCESS: return questionnairesLoadSuccess(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_FETCH_FAIL: return questionnairesLoadFail(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE: return fetchQuestionnaire(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE_SUCCESS: return questionnaireLoadSuccess(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_UPDATE_SUCCESS: return questionnaireUpdateSuccess(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE: return createQuestionnaire(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_SUCCESS: return questionnaireCreateSuccess(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_FAIL: return createQuestionnaireFail(state, action);
        case actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_DELETE_SUCCESS: return questionnaireDeleteSuccess(state, action);

        default: return state;
    }
}

export default reducer;
