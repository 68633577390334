import React from 'react';
import Loadable from 'react-loadable'
import DefaultLayout from './containers/DefaultLayout'

const Loading = () => <div>Loading...</div>;

const Dashboard = Loadable({
  loader: () => import('./views/Role/Client/Dashboard/Dashboard'),
  loading: Loading,
});

const Company = Loadable({
  loader: () => import('./views/Role/Client/Company/Company'),
  loading: Loading,
});

const Presentation = Loadable({
  loader: () => import('./views/Role/Client/Presentation/Presentation'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('./views/Role/Client/Settings/Settings'),
  loading: Loading,
});

const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/company', name: 'Company', component: Company },

  { path: '/presentation', exact:true, name: 'Presentation', component: Presentation },
  { path: '/settings', exact:true, name: 'Settings', component: Settings },
];

export default routes;
