import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils';

const initialState = {
    token: null,
    userId: null,
    role: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    recovery: false,
    email: null,
    allow_export: false,
    allow_see_outcome_count: false
};

const authStart = (state, action) => {
    return updateObject(state, {loading: true, error: null});
}

const ajaxLoading = (state, action) => {
    return updateObject(state, {loading: true, error: null});
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        token: action.access_token,
        userId: action.userId,
        role: action.role,
        allow_export: action.allow_export,
        allow_see_outcome_count: action.allow_see_outcome_count,
        email: null,
        recovery: false
    });
}

const passwordRecoveryRequested = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        recovery: true,
        email: action.email
    });
}


const passwordUpdated = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        token: action.token
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        userId: null,
        token: null
    });
}

const ajaxFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        userId: null,
        token: null
    });
}


const authLogout = (state, action) => {
    return updateObject(state, initialState);
}

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {authRedirectPath:action.path});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_AJAX_FAIL: return ajaxFail(state, action);
        case actionTypes.AUTH_AJAX_START: return ajaxLoading(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.AUTH_PASSWORD_UPDATED: return passwordUpdated(state, action);
        case actionTypes.AUTH_PASSWORD_RECOVERY_SENT: return passwordRecoveryRequested(state, action);
        default: return state;
    }
}

export default reducer;
