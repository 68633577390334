import React from 'react';
import { AppNavbarBrand } from '@coreui/react';
import sygnet from '../../../assets/img/brand/sygnet.svg';

const logo = () => (
    <AppNavbarBrand
      full={{ src: '/assets/img/outcomes.png', width: 89, height: 25, alt: 'Tracker' }}
      minimized={{ src: sygnet, width: 30, height: 30, alt: 'Tracker' }}
    />
);

export default logo;
