import * as actionTypes from './actionTypes'
import axios from '../../axios-logged'

export const loading = () => {
    return {
        type: actionTypes.ADMIN_QUESTION_CAT_FETCH_LOADING,
    }
}

export const questionCategoriesLoadSuccess = (cats) => {
    return {
        type: actionTypes.ADMIN_QUESTION_CAT_FETCH_LIST,
        categories: cats
    }
}

export const questionnaireCatLoadSuccess = (questionnaireCat) => {
    return {
        type: actionTypes.ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE_SUCCESS,
        questionnaireCat: questionnaireCat
    }
}

export const questionnaireCatCreateSuccess = (cat) => {
    return {
        type: actionTypes.ADMIN_QUESTION_CAT_QUESTIONNAIRE_CREATE,
        cat: cat
    }
}

export const questionnaireCatDeleteSuccess = (id) => {
    return {
        type: actionTypes.ADMIN_QUESTION_CAT_QUESTIONNAIRE_DELETE,
        cat: parseInt(id, 10)
    }
}

export const ajaxFail = (error) => {
    return {
        type: actionTypes.ADMIN_QUESTION_CAT_FETCH_FAIL,
        error: error
    }
}

export const fetchQuestionCategories = () => {

    return dispatch => {
        dispatch(loading());

        axios.get('admin/outcomes/categories')
        .then(response => {
            dispatch(questionCategoriesLoadSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const fetchQuestionnaireCat = (id) => {

    return dispatch => {
        axios.get('admin/outcomes/categories/' + id)
        .then(response => {
            dispatch(questionnaireCatLoadSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response));
        });
    }
}

export const updateQuestionnaireCat = (id, data) => {

    return dispatch => {
        axios.put('admin/outcomes/categories/' + id, data)
        .then(response => {
            //dispatch(questionnaireCatUpdateSuccess(response.data));
        })
        .catch(err => {
            //console.log(err.response);
            //dispatch(ajaxFail(err.response));
        });
    }
}

export const createQuestionCategory = (data) => {

    return dispatch => {
        axios.post('admin/outcomes/categories', data)
        .then(response => {
            dispatch(questionnaireCatCreateSuccess(response.data));
        })
        .catch(err => {
            dispatch(ajaxFail('Failed to add category'));
        });
    }
}

export const deleteQuestionCategory = (id) => {

    return dispatch => {
        axios.delete('admin/outcomes/categories/' + id)
        .then(response => {
            dispatch(questionnaireCatDeleteSuccess(id));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}
