import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Row, UncontrolledAlert } from 'reactstrap';
import { connect } from 'react-redux'
import Spinner from '../../../components/UI/Spinner/Spinner'
import { Redirect } from 'react-router-dom'
import FormPasswordRecovery from './Form/PasswordRecovery'
import FormLogin from './Form/LoginForm'
import FormPasswordRecoveryCode from './Form/PasswordRecoveryCodeForm'

import * as actions from '../../../store/actions/index'

class Login extends Component {

    state = {
        login_form: true,
    }

    swapForm = (event) => {
        event.preventDefault();
        this.setState({login_form: !this.state.login_form});
    }

  render() {
      let form = null;

      if(this.state.login_form){
          form = <FormLogin />
      } else if(!this.state.login_form && !this.props.recovery){
          form = <FormPasswordRecovery />
      } else if(!this.state.login_form && this.props.recovery){
          form = <FormPasswordRecoveryCode />
      }

      if(this.props.loading){
          form = <Spinner />;
      }

      let errorMessage = null;
      if(this.props.error){
          errorMessage = (
             <UncontrolledAlert color="danger">{this.props.error}</UncontrolledAlert>
          );
      }

      let authRedirect = null;
      if(this.props.isAuth){
          authRedirect = <Redirect to="/" />;
      }

      let swapBtnTxt = 'Forgot password?';
      if(!this.state.login_form){
          swapBtnTxt = 'Have login?';
      }

      return (
      <div className="app flex-row align-items-center">
        {authRedirect}
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                     {errorMessage}
                     {form}
                     <Row>
                       <Col xs="12" className="text-right">
                         <Button color="link" className="px-0" onClick={event => this.swapForm(event)}>{swapBtnTxt}</Button>
                       </Col>
                     </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuth: state.auth.token !== null,
        recovery: state.auth.recovery
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, pass, isLogin) => dispatch(actions.auth(email, pass, isLogin))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
