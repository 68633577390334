export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}

export const time = () => {
    return new Date();
}

export const isInt = (value) => {
  return !isNaN(value) &&
         parseInt(Number(value), 10) === value &&
         !isNaN(parseInt(value, 10));
}

export const hasNumber = (check) => {
  return /\d/.test(check);
}

export const hasAlphaNumeric = (check) => {
  return /^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/.test(check);
}

export const hasAlphabet = (check) => {
  return /[a-zA-Z]/.test(check);
}

export const hasUpperLetter = (check) => {
  return /[A-Z]/.test(check);
}

export const hasLowerLetter = (check) => {
  return /[a-z]/.test(check);
}

export const isFormValid = (controls) => {
    let formIsValid = true;
    for(let identifier in controls){
        formIsValid = controls[identifier].valid && formIsValid;
    }
    
    return isFormValid;
}

export const checkValidity = ( value, rules ) => {

    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if(rules.required){
        if(!isInt(value)){
            isValid = value.trim() !== '' && isValid;
        }

    } else if(!rules.required && false === rules.required && value.trim() === ''){
        return true;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.containsDigits ){
        isValid = hasNumber(value) && isValid;
    }

    if ( rules.containsAlphabet ){
        isValid = hasAlphabet(value) && isValid;
    }

    if ( rules.containsUpperLetter ) {
        isValid = hasUpperLetter(value) && isValid;
    }

    if ( rules.containsLowerLetter ) {
        isValid = hasLowerLetter(value) && isValid;
    }

    return isValid;
}

export const serializeQuery = (params, prefix) => {
    const query = Object.keys(params).map((key) => {
        const value  = params[key];

        if (params.constructor === Array){
            key = `${prefix}[]`;
        } else if (params.constructor === Object){
            key = (prefix ? `${prefix}[${key}]` : key);
        }

        if (typeof value === 'object'){
            return serializeQuery(value, key);
        } else {
            return `${key}=${encodeURIComponent(value)}`;
        }
    });

    return [].concat.apply([], query).join('&');
}

export const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
            return true;
        }
    }

    return false;
}

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    const expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = cname => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}