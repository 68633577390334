import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import Logo from '../../components/UI/Logo/Logo';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { AppHeaderDropdown } from '@coreui/react';
import avatarImage from '../../assets/img/happy-avatars-placeholders.jpg';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class ClientHeader extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <Logo />

        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="/">Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="/company">Company</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="/presentation">Presentation</NavLink>
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
                  <span src={avatarImage} style={{
                      display: "block",
                      width: "50px",
                      height: "50px",
                      backgroundImage: "url(" + avatarImage +")",
                      backgroundPosition: "-53px -15px"
                  }} />
            </DropdownToggle>
            <DropdownMenu right style={{ right: '5px' }}>
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem href="/settings"><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem onClick={e => this.props.onLogout(e)}><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

ClientHeader.propTypes = propTypes;
ClientHeader.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => {
  return {
      onLogout: () => dispatch(actions.logout())
  };
};

export default connect(null, mapDispatchToProps)(ClientHeader);
