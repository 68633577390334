import React, { Component } from 'react'
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import { connect } from 'react-redux'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { checkValidity } from '../../../../shared/utility'
import * as actions from '../../../../store/actions'

class PasswordRecoveryCodeForm extends Component {

    state = {
        controls: {
            code: {
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                    maxLength: 6
                },
                valid: false,
                touched: false,
            },
            password: {
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                },
                valid: false,
                touched: false,
            },
        },
        formIsValid: false
    }

    inputChangedHandler = event => {
        const inputIdentifier = event.target.id;
        const updatedControls = {
            ...this.state.controls,
            [inputIdentifier]: {
                ...this.state.controls[inputIdentifier],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[inputIdentifier].validation),
                touched: true
            }
        };

        let formIsValid = true;
        for(let inputIdentifier in updatedControls){
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControls, formIsValid: formIsValid});
    }

    submitHandler = event => {
        event.preventDefault();
        if(!this.state.formIsValid){
          const updatedControls = {
              ...this.state.controls
          };
          for (let formElementIdentifier in this.state.controls) {
              updatedControls[formElementIdentifier] = {
                ...this.state.controls[formElementIdentifier],
                touched: true
              };
          }
          this.setState({controls: updatedControls});
          return false;
        }
        this.props.onPasswordResetUpdate(this.props.email, this.state.controls.code.value, this.state.controls.password.value);
    }

    render() {
        if(this.props.loading){
            return <Spinner />;
        }
        
        return (
            <Form onSubmit={this.submitHandler}>
                <h1>Password Reset</h1>
                    <div>
                        <p className="text-muted">Please check your email for security code.</p>
                        <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fa fa-info"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text"
                            id="code"
                            placeholder="Code"
                            autoComplete="code"
                            invalid={!this.state.controls.code.valid && this.state.controls.code.touched}
                            onChange={(event) => this.inputChangedHandler(event) }  />
                        </InputGroup>
                        <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="fa fa-lock"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password"
                            id="password"
                            placeholder="Password"
                            autoComplete="new-password"
                            invalid={!this.state.controls.password.valid && this.state.controls.password.touched}
                            onChange={(event) => this.inputChangedHandler(event) }
                        />
                        </InputGroup>
                        <Row>
                        <Col xs="6">
                            <Button color="warning" className="px-4">Reset</Button>
                        </Col>
                        </Row>
                    </div>
            </Form>
        );
    }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        email: state.auth.email,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onPasswordResetUpdate: (email, token, password) => dispatch(actions.passwordRecoveryChange(email, token, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryCodeForm);
