import React, { Component } from 'react'
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormFeedback } from 'reactstrap'
import { connect } from 'react-redux'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { checkValidity } from '../../../../shared/utility'
import * as actions from '../../../../store/actions'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

class LoginForm extends Component {

    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 5,
                    isEmail: true
                },
                valid: false,
                touched: false,
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 4,
                },
                valid: false,
                touched: false,
            },
        },
        isLogin: true,
        formIsValid: false
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedControls = {
            ...this.state.controls,
            [inputIdentifier]: {
                ...this.state.controls[inputIdentifier],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[inputIdentifier].validation),
                touched: true
            }
        };

        let formIsValid = true;
        for(let inputIdentifier in updatedControls){
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControls, formIsValid: formIsValid});
    }

    swapForm = (event) => {
        event.preventDefault();
        this.setState({login_form: !this.state.login_form});
    }

    submitHandler = (event) => {
        event.preventDefault();
        if(!this.state.formIsValid){
            return ;
        }
        this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value, this.state.isLogin);
    }

  render() {
      let form = (
              <Form onSubmit={this.submitHandler}>
                <h1>Login</h1>
                    <div>
                        <p className="text-muted">Sign In to your account</p>
                        <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-envelope"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text"
                                   placeholder="Email"
                                   autoComplete="Email"
                                   invalid={!this.state.controls.email.valid && this.state.controls.email.touched}
                                   onChange={(event) => this.inputChangedHandler(event, 'email') }  />
                            <FormFeedback>Please enter valid email address!</FormFeedback>
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="password"
                              placeholder="Password"
                              autoComplete="current-password"
                              onChange={(event) => this.inputChangedHandler(event, 'password') }
                          />
                        </InputGroup>
                        <Row>
                          <Col xs="6">
                            <Button color="primary" className="px-4">Login</Button>
                          </Col>
                          <Col xs="6">
                            <GoogleOAuthProvider clientId="1074663093751-j46i4tdccjui05b8hoqmbr9nh9lfqrs7.apps.googleusercontent.com">
                              <GoogleLogin
                                onSuccess={credentialResponse => {
                                  this.props.onGoogleLogin(credentialResponse)
                                }}
                                onError={() => {
                                  console.log('Login Failed');
                                }}
                                useOneTap
                              />
                            </GoogleOAuthProvider>
                          </Col>
                        </Row>
                    </div>
              </Form>
      );

      if(this.props.loading){
          form = <Spinner />;
      }

      return (
      <div>
        {form}
      </div>
    );
  }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, pass, isLogin) => dispatch(actions.auth(email, pass, isLogin)),
        onGoogleLogin: (response) =>  dispatch(actions.authSocialLogin('google', response))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
