import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utils'

const initialState = {
    alert: null,
};

const open = (state, action) => {
    return updateObject(state, {
        alert: action.alert
    });
}

const close = (state, action) => {
    return updateObject(state, {
        alert: null,
    });
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GLOBAL_ALERT_OPEN: return open(state, action);
        case actionTypes.GLOBAL_ALERT_CLOSE: return close(state, action);

        default: return state;
    }
}

export default reducer;
