export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-tachometer'
    },
    {
      title: true,
      name: 'Navigation',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Companies',
      url: '/companies',
      icon: 'fa fa-suitcase',
      children: [
        {
          name: 'Add',
          url: '/companies/create',
          icon: 'fa fa-plus',
        },
        {
          name: 'List',
          url: '/companies',
          icon: 'fa fa-book',
        },
      ]
    },
    {
      name: 'Brands',
      url: '/brands',
      icon: 'fa fa-tag',
      children: [
        {
          name: 'Add',
          url: '/brands/create',
          icon: 'fa fa-plus',
        },
        {
          name: 'List',
          url: '/brands',
          icon: 'fa fa-book',
        },
      ]
    },
    {
      name: 'Questionnaires',
      url: '/questionnaires',
      icon: 'fa fa-question',
      children: [
        {
          name: 'Add',
          url: '/questionnaires/create',
          icon: 'fa fa-plus',
        },
        {
          name: 'List',
          url: '/questionnaires',
          icon: 'fa fa-book',
        },
        {
          name: 'Categories',
          url: '/questionnaires/categories',
          icon: 'fa fa-folder',
        },
      ]
    },
    {
      title: true,
      name: 'Data',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Outcomes',
      url: '/outcomes',
      icon: 'fa fa-database',
    },
    {
      name: 'Presentation',
      url: '/presentation',
      icon: 'fa fa-bar-chart',
      children: [
        {
          name: 'Agents',
          url: '/presentation/agents-performance'
        },
        {
          name: 'Company',
          url: '/presentation/company-performance'
        },
      ]
    },
    {
      name: 'Export',
      url: '/exports',
      icon: 'fa fa-cloud-download',
    },
    {
      title: true,
      name: 'Management',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'fa fa-users',
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'fa fa-cog',
    },
  ]
}
