import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://api.outcomes.local' : 'https://api-outcomes.newstel.com',
    //baseURL:  'https://api.outcomes.newstel.com',
    headers: {
        Authorization: {
          toString () {
            return `Bearer ${localStorage.getItem('token')}`
          }
        }
    }
});

export default instance;
