import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utils'

const initialState = {
    list: [],
    category: null,
    error: null,
    loading: false,
    success: false
};

const loading = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: false
    });
}


const ajaxFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: false,
        error: action.error
    });
}

const questionCategoriesLoad = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        list: action.categories
    });
}

const createQuestionCategoriesSuccess = (state, action) => {
    const oldState = {...state};
    const updatedList = [...oldState.list];
    updatedList.push(action.cat);

    return updateObject(state, {
        loading: false,
        error: null,
        list: updatedList
    });
}

const deleteQuestionCategoriesSuccess = (state, action) => {
    const oldState = {...state};
    const oldList = [...oldState.list];
    const updatedList = oldList.filter(elm => elm.id !== action.cat);

    return updateObject(state, {
        loading: false,
        error: null,
        list: updatedList
    });
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_QUESTION_CAT_FETCH_LOADING: return loading(state, action);
        case actionTypes.ADMIN_QUESTION_CAT_FETCH_FAIL: return ajaxFail(state, action);
        case actionTypes.ADMIN_QUESTION_CAT_FETCH_LIST: return questionCategoriesLoad(state, action);
        case actionTypes.ADMIN_QUESTION_CAT_QUESTIONNAIRE_CREATE: return createQuestionCategoriesSuccess(state, action);
        case actionTypes.ADMIN_QUESTION_CAT_QUESTIONNAIRE_DELETE: return deleteQuestionCategoriesSuccess(state, action);

        default: return state;
    }
}

export default reducer;
