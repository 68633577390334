import React, { Component } from 'react'
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap'
import { connect } from 'react-redux'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { checkValidity } from '../../../../shared/utility'
import * as actions from '../../../../store/actions'

class PasswordRecovery extends Component {

    state = {
        value: '',
        validation: {
            required: true,
            minLength: 5,
            isEmail: true
        },
        valid: false,
        touched: false,
    }

    inputChangedHandler = (event) => {
        this.setState({
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.validation),
            touched: true
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        if(!this.state.valid){
            return ;
        }
        this.props.onRecovery(this.state.value);
    }

    render() {
        let form = <Spinner />;
        if(!this.props.loading){
            form = (
                <Form onSubmit={this.submitHandler}>
                    <h1>Password Recovery</h1>
                        <div>
                            <p className="text-muted">Please type in email for password recovery</p>
                            <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                <i className="fa fa-envelope"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text"
                                placeholder="Email"
                                autoComplete="Email"
                                onChange={(event) => this.inputChangedHandler(event) }  />
                            </InputGroup>
                            <Row>
                            <Col xs="6">
                                <Button color="primary" className="px-4">Recover</Button>
                            </Col>
                            </Row>
                        </div>
                </Form>
            );
        }

      return form;
    }
}


const mapStateToProps = state => {
    return {
        loading: state.auth.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRecovery: (email) => dispatch(actions.passwordRecovery(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
