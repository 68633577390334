export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-tachometer'
    },
    {
      name: 'Company',
      url: '/company',
      icon: 'fa fa-suitcase',
    },
    {
      name: 'Presentation',
      url: '/presentation',
      icon: 'fa fa-bar-chart',
    }
  ]
}
