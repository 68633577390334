import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utils'

const initialState = {
    users: [],
    user: null,
    error: null,
    loading: false,
    success: false,
    successMessage: null,
    avatar: 1
};

const ajaxLoading = (state, action) => {
    return updateObject(state, {
        loading: true,
        success: false,
        error: null
    });
}

const ajaxFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: false,
        error: action.error
    });
}

const createUser = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: false
    });
}

const fetchUsersListSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: false,
        users: action.users,
        user: null,
    });
}

const userLoadSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: false,
        user: action.user,
    });
}

const userUpdateSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: true,
        user: action.user,
        successMessage: 'User Updated.',
    });
}

const userCreateSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: true,
        user: action.user,
        successMessage: 'User Created.',
    });
}

const passwordUpdated = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: true,
        successMessage: 'Password Updated.',
    });
}

const avatarUpdated = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        avatar: action.avatar
    });
}

const deleteSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        success: false,
        user: null,
    });
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_USERS_AJAX_LOADING: return ajaxLoading(state, action);
        case actionTypes.ADMIN_USERS_AJAX_FAIL: return ajaxFail(state, action);
        case actionTypes.ADMIN_USERS_FETCH_LIST_SUCCESS: return fetchUsersListSuccess(state, action);
        case actionTypes.ADMIN_USERS_FETCH_USER_SUCCESS: return userLoadSuccess(state, action);
        case actionTypes.ADMIN_USERS_USER_UPDATE_SUCCESS: return userUpdateSuccess(state, action);
        case actionTypes.ADMIN_USERS_USER_CREATE: return createUser(state, action);
        case actionTypes.ADMIN_USERS_USER_CREATE_SUCCESS: return userCreateSuccess(state, action);
        case actionTypes.ADMIN_USERS_USER_DELETE_SUCCESS: return deleteSuccess(state, action);
        case actionTypes.ADMIN_USERS_USER_PASSOWORD_UPDATE_SUCCESS: return passwordUpdated(state, action);
        case actionTypes.ADMIN_USERS_USER_AVATAR_UPDATE_SUCCESS: return avatarUpdated(state, action);

        default: return state;
    }
}

export default reducer;
