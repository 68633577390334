import * as actionTypes from './actionTypes'
import axios from '../../axios-logged'

export const questionnairesLoadSuccess = (questionnaires) => {
    return {
        type: actionTypes.ADMIN_QUESTIONNAIRES_FETCH_SUCCESS,
        questionnaires: questionnaires
    }
}

export const questionnaireLoadSuccess = (questionnaire) => {
    return {
        type: actionTypes.ADMIN_QUESTIONNAIRES_FETCH_QUESTIONNAIRE_SUCCESS,
        questionnaire: questionnaire
    }
}

export const questionnaireUpdateSuccess = (questionnaire, saveAndExit) => {
    return {
        type: actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_UPDATE_SUCCESS,
        questionnaire: questionnaire,
        saveAndExit: saveAndExit
    }
}

export const questionnaireCreateSuccess = (questionnaire) => {
    return {
        type: actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_SUCCESS,
        questionnaire: questionnaire
    }
}

export const questionnaireDeleteSuccess = () => {
    return {
        type: actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_DELETE_SUCCESS
    }
}

export const questionnaireCreateFail = error => {
  return {
      type: actionTypes.ADMIN_QUESTIONNAIRES_QUESTIONNAIRE_CREATE_FAIL,
      error: error
  }
}

export const questionnaireLoadFail = (error) => {
    return {
        type: actionTypes.ADMIN_QUESTIONNAIRES_FETCH_FAIL,
        error: error
    }
}

export const fetchQuestionnaires = () => {

    return dispatch => {
        axios.get('admin/outcomes')
        .then(response => {
            dispatch(questionnairesLoadSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(questionnaireLoadFail(err.response.data.message));
        });
    }
}

export const fetchQuestionnaire = (id) => {

    return dispatch => {
        axios.get('admin/outcomes/' + id)
        .then(response => {
            dispatch(questionnaireLoadSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(questionnaireLoadFail(err.response));
        });
    }
}

export const updateQuestionnaire = (id, data, saveAndExit) => {

    return dispatch => { 
        axios.put('admin/outcomes/' + id, data)
        .then(response => {
            dispatch(questionnaireUpdateSuccess(response.data, saveAndExit));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(questionnaireLoadFail(err.response.data.message));
        });
    }
}

export const createQuestionnaire = (data) => {

    return dispatch => {
        axios.post('admin/outcomes', data)
        .then(response => {
            dispatch(questionnaireCreateSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(questionnaireCreateFail(err.response.data.message));
        });
    }
}

export const deleteQuestionnaire = (id) => {

    return dispatch => {
        axios.delete('admin/outcomes/' + id)
        .then(response => {
            dispatch(questionnaireDeleteSuccess());
        })
        .catch(err => {
            console.log(err.response);
            dispatch(questionnaireCreateFail(err.response.data.message));
        });
    }
}
