import React from 'react';
import Loadable from 'react-loadable'
import DefaultLayout from './containers/DefaultLayout'

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Role/Admin/Dashboard/Dashboard'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/Users/User'),
  loading: Loading,
});

const UserCreate = Loadable({
  loader: () => import('./views/Users/UserCreate'),
  loading: Loading,
});

const Companies = Loadable({
  loader: () => import('./views/Role/Admin/Companies/Companies'),
  loading: Loading,
});

const CompanyCreate = Loadable({
  loader: () => import('./views/Role/Admin/Companies/CompanyCreate'),
  loading: Loading,
});

const Company = Loadable({
  loader: () => import('./views/Role/Admin/Companies/Company'),
  loading: Loading,
});

const Brands = Loadable({
  loader: () => import('./views/Role/Admin/Brands/Brands'),
  loading: Loading,
});

const BrandCreate = Loadable({
  loader: () => import('./views/Role/Admin/Brands/BrandCreate'),
  loading: Loading,
});

const Brand = Loadable({
  loader: () => import('./views/Role/Admin/Brands/Brand'),
  loading: Loading,
});

const Questionnaires = Loadable({
  loader: () => import('./views/Questionnaires/Questionnaires'),
  loading: Loading,
});

const Questionnaire = Loadable({
  loader: () => import('./views/Questionnaires/Questionnaire'),
  loading: Loading,
});

const QuestionnaireCreate = Loadable({
  loader: () => import('./views/Questionnaires/QuestionnaireCreate'),
  loading: Loading,
});

const QuestionnairesCategory = Loadable({
  loader: () => import('./views/Questionnaires/Categories'),
  loading: Loading,
});

const AgentOutcomes = Loadable({
  loader: () => import('./views/Role/Admin/Outcomes/Outcomes'),
  loading: Loading,
});

const Exports = Loadable({
  loader: () => import('./views/Role/Admin/Exports/Exports'),
  loading: Loading,
});

const Presentation = Loadable({
  loader: () => import('./views/Role/Admin/Presentation/Presentation'),
  loading: Loading,
});

const PresentationAgentPerformance = Loadable({
  loader: () => import('./views/Role/Admin/Presentation/AgentPerformance'),
  loading: Loading,
});

const PresentationCompanyPerformance = Loadable({
  loader: () => import('./views/Role/Admin/Presentation/CompanyPerformance'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('./views/Role/Admin/Settings/Settings'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/companies', exact:true, name: 'Companies', component: Companies },
  { path: '/companies/create', exact:true, name: 'Create', component: CompanyCreate },
  { path: '/companies/:id', exact:true, name: 'View', component: Company },
  { path: '/brands', exact:true, name: 'Brands', component: Brands },
  { path: '/brands/create', exact:true, name: 'Create', component: BrandCreate },
  { path: '/brands/:id', exact:true, name: 'View', component: Brand },
  { path: '/outcomes', exact:true, name: 'Outcomes', component: AgentOutcomes },
  { path: '/questionnaires', exact:true, name: 'Questionnaires', component: Questionnaires },
  { path: '/questionnaires/create', exact:true, name: 'Create', component: QuestionnaireCreate },
  { path: '/questionnaires/categories', exact:true, name: 'Categories', component: QuestionnairesCategory },
  { path: '/questionnaires/:id', exact:true, name: 'View', component: Questionnaire },

  { path: '/settings', exact:true, name: 'Settings', component: Settings },
  { path: '/exports', exact:true, name: 'Export', component: Exports },
  { path: '/presentation', exact:true, name: 'Presentation', component: Presentation },
  { path: '/presentation/agents-performance', exact:true, name: 'Agent Performance', component: PresentationAgentPerformance },
  { path: '/presentation/company-performance', exact:true, name: 'Company Performance', component: PresentationCompanyPerformance },

  { path: '/users', exact: true,  name: 'Users', component: Users },
  { path: '/users/create', exact: true, name: 'User Create', component: UserCreate },
  { path: '/users/:id', exact: true, name: 'User Details', component: User },
];

export default routes;
