import * as actionTypes from './actionTypes';
import axios from '../../axios-logged';

export const brandsLoadSuccess = brands => {
    const companies = [];
    for(let i = 0; i < brands.length; i++){
        let found = companies.find(obj => obj.id === brands[i].company.id)
        if(!found){
            companies.push(brands[i].company);
        }
    }
    return {
        type: actionTypes.AGENT_BRANDS_FETCH_LIST,
        brands: brands,
        companies: companies,
    }
}

export const loading = () => {
    return {
        type: actionTypes.AGENT_LOADING,
    }
}

export const initSuccess = (tracking_id, brand) => {
    return {
        type: actionTypes.AGENT_BRAND_INIT,
        brand: brand,
        tracking_id: tracking_id,
        title: 'Communication channel',
        text: 'Communication channel',
        questions: brand.outcomes,
        track_gender: brand.track_gender,
        languages: brand.language.split('|'),
        track_language: brand.language.split('|').length > 1,
    }
}

export const agentInfoSuccess = agent => {
    return {
        type: actionTypes.AGENT_FETCH_INFO_SUCCESS,
        agent: agent
    }
}


export const nextStepSuccess = obj => {
    return {
        type: actionTypes.AGENT_NEXT_STEP,
        outcome_id: obj.id,
        title: obj.title,
        text: obj.text,
        questions: obj.outcomes,
    }
}

export const submitSuccess = data => {
    return {
        type: actionTypes.AGENT_SUBMIT_SUCCESS
    }
}

export const clearAgentSubmit = () => {
    return {
        type: actionTypes.AGENT_RESET
    }
}

export const infoUpdateSuccess = agent => {
    return {
        type: actionTypes.AGENT_INFO_UPDATE,
        agent: agent
    }
}

export const ajaxFail = error => {
    return {
        type: actionTypes.AGENT_AJAX_FAIL,
        error: error
    }
}

export const agentPreviousStep = () => {
    return {
        type: actionTypes.AGENT_PREVIOUS_STEP
    }
}

export const fetchAgentBrands = () => {

    return dispatch => {
        dispatch( loading() );
        axios.get('brands')
        .then(response => {
            dispatch(brandsLoadSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const fetchAgentInfo = () => {

    return dispatch => {
        dispatch( loading() );

        axios.get('auth/user')
        .then(response => {
            dispatch(agentInfoSuccess(response.data.user));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}


export const initQuestionary = id => {

    return dispatch => {
        dispatch( loading() );

        axios.post('brands/' + id)
        .then(response => {
            dispatch(initSuccess(response.data.tracking.id, response.data.brand));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const agentNextStep = (id) => {

    return dispatch => {
        dispatch( loading() );

        axios.get('outcomes/' + id)
        .then(response => {
            dispatch(nextStepSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const agentSubmit = (data) => {

    return dispatch => {
        dispatch( loading() );

        axios.put('brands/' + data.brand_id, data)
        .then(response => {
            dispatch(submitSuccess(response.data));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}

export const userInfoUpdate = (data) => {

    return dispatch => {
        dispatch( loading() );

        axios.post('auth/user', data)
        .then(response => {
            dispatch(infoUpdateSuccess(response.data.user));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(ajaxFail(err.response.data.message));
        });
    }
}
