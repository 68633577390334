import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav_client'
// routes config
import routes from '../../routes_client'
import ClientHeader from './ClientHeader'
import Loadable from 'react-loadable'

const Loading = () => <div>Loading...</div>;

const Exports = Loadable({
  loader: () => import('../../views/Role/Client/Exports/Exports'),
  loading: Loading,
});

class ClientLayout extends Component {
  render() {
    console.log('this.props', this.props)
    if(this.props.allow_export && navigation.items.filter(e => e.url === '/exports').length === 0) {
        routes.push({ path: '/exports', exact:true, name: 'Export', component: Exports })
        navigation.items.push({name: 'Export', url: '/exports', icon: 'fa fa-cloud-download'});
    }
    return (
      <div className="app">
        <AppHeader fixed>
          <ClientHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={navigation} {...this.props} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      allow_export: state.auth.allow_export
  }
}

export default connect( mapStateToProps )( ClientLayout );

//export default ClientLayout;